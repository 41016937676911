import * as React from 'react'

import '../assets/app.css'
import '../assets/bootstrap.min.css'
import '../assets/fontawesome-svg-with-js.css'

import Meta, { OpenGraphImage } from './Meta';
import AnnouncementModal from './AnnouncementModal';

import 'bootstrap-icons/font/bootstrap-icons.css';

interface Props {
  subtitle?: string,
  description?: string,
  sharingImage?: OpenGraphImage,
  isBlog?: boolean,
  sharingUrl?: string,
  noIndex?: boolean,
  keywords?: string,
  isStartPage?: boolean,
}

interface State {

}

// TODO: Reintroduce the AnnouncementModal via <AnnouncementModal /> after {this.props.children}
export default class PageContainer extends React.Component<Props, State> {
  render() {
    return (
      <div className="d-flex flex-column min-vh-100 m-0 p-0">
        <Meta
          subtitle={this.props.subtitle}
          description={this.props.description}
          keywords={this.props.keywords}
          sharingImage={this.props.sharingImage}
          isBlog={this.props.isBlog}
          sharingUrl={this.props.sharingUrl}
          noIndex={this.props.noIndex}
          isStartPage={this.props.isStartPage}
        />
        {this.props.children}
      </div>
    );
  }
}

